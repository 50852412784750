import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import { Box, Button, Menu, MenuItem, Checkbox, ListItemText, Divider } from '@mui/material'
import { MRT_Column, MRT_TableInstance } from 'material-react-table'
import { useFormatMessage } from '@hooks/util'
import { JobStatistics } from '../../../types'
import { InfoIconWithTooltip } from '../../InfoIconWithTooltip'
import TypographyWithIcon from '../../TypographyWithIcon'

export default function Header({ table }: { table: MRT_TableInstance<JobStatistics> }) {
  const formatMessage = useFormatMessage()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const sections = useMemo(
    () => [table.getColumn('job'), table.getColumn('performance_during_period'), table.getColumn('current_data')],
    [table]
  )

  const showHideSection = useCallback((section: MRT_Column<JobStatistics>) => {
    const isVisible = section.getIsVisible()
    const toggleColumnVisibility = (column: MRT_Column<JobStatistics>) => {
      column.toggleVisibility(!isVisible)
      column.columns?.forEach(toggleColumnVisibility)
    }

    toggleColumnVisibility(section)
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'inherit',
        flexDirection: 'row',
        gap: '8px',
        justifyContent: 'space-between',
        maxWidth: 1140,
        margin: 'auto',
      }}
    >
      <TypographyWithIcon
        variant="h6"
        mb={2}
        icon={<InfoIconWithTooltip tooltip={formatMessage('reach_stats_jobs_overview_title_tooltip')} />}
      >
        <FormattedMessage id="reach_stats_jobs_overview_title" />
      </TypographyWithIcon>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Button
          component="label"
          variant="text"
          tabIndex={-1}
          startIcon={<ViewColumnIcon />}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <FormattedMessage id="reach_stats_jobs_overview_show_sections_button" />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem key="all" onClick={() => table.toggleAllColumnsVisible()}>
            <Checkbox
              checked={table.getIsAllColumnsVisible()}
              indeterminate={!table.getIsAllColumnsVisible() && sections.some((section) => section.getIsVisible())}
            />
            <ListItemText primary={formatMessage('reach_stats_job_overview_show_all_sections_option')} />
          </MenuItem>
          <Divider />
          {sections.map((section) => (
            <MenuItem key={section.id} onClick={() => showHideSection(section)}>
              <Checkbox checked={section.getIsVisible()} />
              <ListItemText primary={formatMessage(`reach_stats_jobs_overview_${section.id}_header`)} />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  )
}
