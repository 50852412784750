import { useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { PaginationState, SortingState } from '@tanstack/table-core'
import { MRT_ColumnDef, MRT_TableInstance, useMaterialReactTable } from 'material-react-table'
import { MRT_Localization_DE } from 'material-react-table/locales/de'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { useFormatMessage } from '@hooks/util'
import { ReachStatsContext } from '../../../pages/Statistics/Reach/contexts/ReachStatsContext'
import { useJobsStatistics } from '../../../pages/Statistics/Reach/hooks/useReachStats'
import { JobStatistics, JobsOverviewSort, JobsOverviewSortOption } from '../../../types'
import { columnsFactory } from './helpers'
import NoRecords from './NoRecords'

const defaultPaginationState: PaginationState = {
  pageIndex: 1,
  pageSize: 10,
}

const defaultSortingState: SortingState = []

interface JobsDatagridHook {
  table: MRT_TableInstance<JobStatistics>
  pagination: PaginationState
  setPagination: (pagination: PaginationState) => void
}

export default function useJobsDatagrid(): JobsDatagridHook {
  const [pagination, setPagination] = useState<PaginationState>(defaultPaginationState)
  const [sorting, setSorting] = useState<SortingState>(defaultSortingState)
  const { filters } = useContext(ReachStatsContext)

  // Resets pagination and sorting when global filters change
  useEffect(() => {
    setPagination(defaultPaginationState)
    setSorting(defaultSortingState)
  }, [filters])

  // Resets pageIndex when sorting changes
  useEffect(() => {
    setPagination((prevState) => ({ ...prevState, pageIndex: defaultPaginationState.pageIndex }))
  }, [sorting])

  const formatMessage = useFormatMessage()
  const intl = useIntl()

  const columns = useMemo<MRT_ColumnDef<JobStatistics>[]>(() => columnsFactory(formatMessage), [formatMessage])

  const localization = useMemo(() => (intl.locale === 'de' ? MRT_Localization_DE : MRT_Localization_EN), [intl])

  const querySort: JobsOverviewSort = useMemo(
    () =>
      sorting.length === 0
        ? undefined
        : { key: sorting[0].id as JobsOverviewSortOption, direction: sorting[0].desc ? 'desc' : 'asc' },
    [sorting]
  )

  const { isLoading, jobsStats, totalItems } = useJobsStatistics(pagination, querySort)

  const table = useMaterialReactTable<JobStatistics>({
    columns,
    data: jobsStats,
    enableColumnPinning: true,
    enableColumnActions: false,
    layoutMode: 'semantic',
    enableTableHead: false,
    initialState: {
      columnPinning: { left: ['id'] },
    },
    manualSorting: true,
    manualPagination: true,
    enableTopToolbar: false,
    enableColumnFilterModes: true,
    enableFullScreenToggle: false,
    onSortingChange: setSorting,
    enableMultiSort: false,
    state: {
      sorting,
      isLoading,
      pagination,
    },
    muiSkeletonProps: {
      width: 'unset',
    },
    enableStickyHeader: true,
    localization,
    renderEmptyRowsFallback: NoRecords,
    muiTableProps: {
      sx: {
        borderCollapse: 'collapse',
      },
    },
    meta: {
      totalCount: totalItems,
    },
  })

  return {
    table,
    pagination,
    setPagination,
  }
}
