import React, { PropsWithChildren } from 'react'
import { Box, Typography } from '@mui/material'
import { useFormatMessage } from '@hooks/util'
import { InfoIconWithTooltip } from '../../InfoIconWithTooltip'
import TypographyWithIcon from '../../TypographyWithIcon'
import SummaryCard from './SummaryCard'

type StatisticCardProps = {
  labelKey: string
  value: string
}

function StatisticCard({ labelKey, value, children }: PropsWithChildren<StatisticCardProps>) {
  const formatMessage = useFormatMessage()

  return (
    <SummaryCard
      gutterBottom
      title={
        <TypographyWithIcon
          variant="subtitle3"
          icon={<InfoIconWithTooltip tooltip={formatMessage(`${labelKey}_tooltip`)} />}
        >
          {formatMessage(`${labelKey}_label`)}
        </TypographyWithIcon>
      }
    >
      <Box display="inline-flex" justifyContent="flex-start" alignItems="center" gap={3}>
        <Typography variant="h4">{value}</Typography>
        {children}
      </Box>
    </SummaryCard>
  )
}

export default StatisticCard
