import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import TrendingDown from '@mui/icons-material/TrendingDown'
import TrendingUp from '@mui/icons-material/TrendingUp'
import { Typography, Box } from '@mui/material'
import { formatPercentage } from '../../../utils/formatters'

interface ComparisonValueProps {
  current: number | null
  previous: number | null
  label: string
  reverseColors?: boolean
  disabled?: boolean
  noIcon?: boolean
  noNegative?: boolean
}

function ComparisonValue({
  current,
  previous,
  label,
  reverseColors = false,
  disabled = false,
  noIcon = false,
  noNegative = false,
}: ComparisonValueProps) {
  const intl = useIntl()

  if (current === null || previous === null || disabled) {
    return (
      <Typography variant="caption" color="text.secondary">
        <FormattedMessage id="reach_stats_summary_no_sufficient_reference_timeframe" />
      </Typography>
    )
  }

  const percentageChange = previous === 0 ? (current > 0 ? 1 : 0) : (current - previous) / Math.abs(previous)
  const isPositive = reverseColors ? percentageChange <= 0 : percentageChange >= 0

  const color = isPositive ? 'success.main' : 'error.main'
  const Icon = isPositive ? TrendingUp : TrendingDown

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      {!noIcon && <Icon fontSize="small" sx={{ color }} />}
      <Typography variant="caption" sx={{ color }}>
        {formatPercentage(intl.locale, percentageChange, { signDisplay: noNegative ? 'never' : 'auto' })}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {label}
      </Typography>
    </Box>
  )
}

export default ComparisonValue
