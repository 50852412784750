import React, { useMemo } from 'react'
import { Chart } from 'react-chartjs-2'
import { FormattedMessage, useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import { ChartData, ChartOptions } from 'chart.js'
import { useFormatMessage } from '@hooks/util'
import { useCampaignStats, useCampaignStatsCpa } from '../../../pages/Statistics/Reach/hooks/useReachStats'
import { DateRange } from '../../../types'
import { formatCurrency } from '../../../utils/formatters'
import { $colors } from '../../dependencies/variables'
import { InfoIconWithTooltip } from '../../InfoIconWithTooltip'
import TypographyWithIcon from '../../TypographyWithIcon'
import Loader from '../Loader'
import CustomLegend from './CustomLegend'
import { LegendItem } from './types'

interface LiveJobsVsCpaChartData {
  dateRanges: DateRange[]
  liveJobsCounts: number[]
  cpaValues: number[]
  liveJobsTarget: number
}

function _LiveJobsVsCpaChart() {
  const intl = useIntl()
  const formatMessage = useFormatMessage()
  const { stats } = useCampaignStats()
  const { cpaStats } = useCampaignStatsCpa()
  const delivery = stats!.linkout_exists ? 'lo' : 'nlo'

  const data: LiveJobsVsCpaChartData = useMemo(() => {
    const preparedData: LiveJobsVsCpaChartData = {
      dateRanges: [],
      liveJobsCounts: [],
      cpaValues: [],
      liveJobsTarget: cpaStats.target_job_count ?? 0,
    }
    cpaStats.data.forEach((item) => {
      preparedData.dateRanges.push({ from: item.begin_date, to: item.end_date })
      preparedData.liveJobsCounts.push(item.jobs_live_count)
      preparedData.cpaValues.push(item.cpa)
    })
    return preparedData
  }, [cpaStats])

  const chartData: ChartData = {
    datasets: [
      {
        type: 'line',
        data: data.liveJobsCounts,
        borderColor: $colors.primary[500],
        borderWidth: 2,
        backgroundColor: $colors.primary[500],
        yAxisID: 'y-live-jobs-scale',
        pointRadius: 0,
        animation: false,
        datalabels: { display: false },
      },
      {
        type: 'bar',
        data: data.cpaValues,
        backgroundColor: $colors.primary[12],
        yAxisID: 'y-cpa-scale',
        barThickness: 36,
        datalabels: {
          align: 'top',
          anchor: 'end',
          color: $colors.text.darkPrimary,
          formatter(value) {
            return formatCurrency(intl.locale, value)
          },
        },
      },
    ],
  }

  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      'x': {
        display: true,
        type: 'category',
        grid: {
          display: false,
        },
        labels: data.dateRanges.map((date_range) => date_range.from),
        ticks: {
          labelOffset: 18,
          crossAlign: 'near',
        },
      },
      'y-live-jobs-scale': {
        display: true,
        suggestedMax: data.liveJobsTarget * 1.1,
        type: 'linear',
        beginAtZero: true,
        grace: '10%',
      },
      'y-cpa-scale': {
        display: false,
        position: 'right',
        type: 'linear',
        grace: '20%',
      },
    },
    plugins: {
      // @ts-expect-error
      'breakable-y-axis': false,
      'annotation': {
        annotations: {
          targetLine: {
            type: 'line',
            yMin: data.liveJobsTarget,
            yMax: data.liveJobsTarget,
            borderColor: $colors.primary[500],
            borderWidth: 1,
            yScaleID: 'y-live-jobs-scale',
            borderDash: [5, 5], // Dashed line
          },
        },
      },
      'tooltip': {
        callbacks: {
          title(tooltipItems) {
            const index = tooltipItems[0].dataIndex
            const dateRange = data.dateRanges[index]
            return `${dateRange.from} - ${dateRange.to}`
          },
        },
      },
      'legend': {
        display: false,
      },
    },
  }

  const legend: LegendItem[] = [
    {
      type: 'line',
      color: $colors.primary[500],
      label: formatMessage('reach_stats_cpa_chart_label_live_jobs'),
    },
    {
      type: 'dashedLine',
      color: $colors.primary[500],
      label: formatMessage('reach_stats_funnel_chart_label_campaign_target'),
    },
    {
      type: 'rectangle',
      color: $colors.primary[12],
      label: formatMessage(`reach_stats_funnel_chart_label_cpa_${delivery}`),
    },
  ]

  return (
    <>
      <CustomLegend items={legend} />
      <Box height={237}>
        <Chart id="live-jobs-vs-cpa-chart" type="line" data={chartData} options={options} />
      </Box>
    </>
  )
}

export default function LiveJobsVsCpaChart() {
  const { isSuccess: isStatsSuccess } = useCampaignStats()
  const { isSuccess: isCpaSuccess } = useCampaignStatsCpa()
  const formatMessage = useFormatMessage()

  return (
    <Box>
      <TypographyWithIcon
        variant="h6"
        mb={2}
        icon={<InfoIconWithTooltip tooltip={formatMessage('reach_stats_cpa_chart_title_tooltip')} />}
      >
        <FormattedMessage id="reach_stats_cpa_chart_title" />
      </TypographyWithIcon>
      <Box height={277}>{isStatsSuccess && isCpaSuccess ? <_LiveJobsVsCpaChart /> : <Loader />}</Box>
    </Box>
  )
}
