import React, { useCallback, useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { FormattedMessage } from 'react-intl'
import Box from '@mui/material/Box'
import { ChartOptions, ChartData } from 'chart.js'
import { useCurrentRecruiter } from '@hooks/store'
import { useFormatMessage } from '@hooks/util'
import { DEFAULT_LOCALE } from '../../../../../cypress/support/formatMessage'
import { useCampaignStats } from '../../../pages/Statistics/Reach/hooks/useReachStats'
import { HexColor } from '../../../types'
import { buildNumberFormatter } from '../../../utils/formatters'
import { InfoIconWithTooltip } from '../../InfoIconWithTooltip'
import TypographyWithIcon from '../../TypographyWithIcon'
import Loader from '../Loader'
import BoldXAxis from './BoldXAxis'

const colors: HexColor[] = ['#CED9EF', '#9EB4DF', '#3D69BE', '#182A4C']

const xAxisFields: string[] = [
  'views',
  'application_started_count',
  'application_submitted_count',
  'application_sent_count',
]

function _CampaignFunnel() {
  const recruiter = useCurrentRecruiter()
  const formatMessage = useFormatMessage()
  const { stats: campaignStats } = useCampaignStats()

  const formatNumber = useCallback(
    (number: number) => buildNumberFormatter(recruiter?.locale || DEFAULT_LOCALE)(number),
    [recruiter?.locale]
  )

  const stats = useMemo(() => {
    return xAxisFields.map((field, index) => {
      const value = campaignStats![field]
      const data = {
        color: colors[index],
        yAxis: new Array(3).fill(value),
        xAxis: new Array(3).fill(''),
      }

      data.xAxis[0] = [formatNumber(value), formatMessage('reach_stats_funnel_chart_label_' + field)]

      return data
    })
  }, [campaignStats, formatMessage, formatNumber])

  const data = useMemo<ChartData<'line'>>(
    () => ({
      datasets: [
        {
          data: stats.flatMap((stat) => stat.yAxis),
          fill: true,
          borderWidth: 0,
          pointRadius: 0,
          segment: {
            backgroundColor(ctx) {
              const index = Math.floor(ctx.p0DataIndex / 3)
              return colors[index] || colors[colors.length - 1]
            },
          },
        },
      ],
    }),
    [stats]
  )

  const options = useMemo<ChartOptions<'line'>>(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: { display: false },
        legend: { display: false },
      },
      scales: {
        y: {
          type: 'linear',
          beginAtZero: true,
        },
        x: {
          type: 'category',
          grid: { display: false },
          labels: stats.flatMap((stat) => stat.xAxis),
          ticks: {
            minRotation: 0,
            maxRotation: 0,
            autoSkip: false,
          },
        },
      },
    }),
    [stats]
  )

  return <Line id="campaign-funnel" data={data} options={options} plugins={[BoldXAxis]} />
}

export default function CampaignFunnel() {
  const formatMessage = useFormatMessage()
  const { isSuccess } = useCampaignStats()

  return (
    <Box>
      <TypographyWithIcon
        variant="h6"
        mb={2}
        icon={<InfoIconWithTooltip tooltip={formatMessage('reach_stats_funnel_chart_title_tooltip')} />}
      >
        <FormattedMessage id="reach_stats_funnel_chart_title" />
      </TypographyWithIcon>
      <Box height={234}>{isSuccess ? <_CampaignFunnel /> : <Loader />} </Box>
    </Box>
  )
}
