import React from 'react'
import { FormattedMessage } from 'react-intl'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import { useField } from 'formik'
import styled from 'styled-components'
import RuntimeHintIcon from '@images/runtime-hint-icon.svg?react'
import { $colors, $sizes } from '../../dependencies/variables'
import { RECOMMENDED_RUNTIME } from '../constants'
import { JobRuntimeType } from '../types'

const StyledHint = styled(Alert)`
  border: none;
  padding: 0;

  .MuiAlert-icon {
    margin-right: ${$sizes.sm};
  }
`

const RuntimeHints = () => {
  const [{ value: runtime }] = useField<JobRuntimeType>('runtime')

  const showHints = parseInt(runtime) < parseInt(RECOMMENDED_RUNTIME)

  return (
    <Collapse in={showHints} data-testid="runtime-hints">
      <StyledHint icon={<RuntimeHintIcon />} variant="outlined" data-testid="runtime-hint-no-extra-cost">
        <Typography variant="subtitle2" display="inline" color={$colors.text.darkPrimary}>
          <FormattedMessage id="publish_job_runtime_hint_no_extra_costs_heading" />
        </Typography>{' '}
        <Typography variant="body2" display="inline" color="textSecondary">
          <FormattedMessage id="publish_job_runtime_hint_no_extra_costs_text" />
        </Typography>
      </StyledHint>
    </Collapse>
  )
}

export default RuntimeHints
