import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@mui/material'
import SleepingJobster from '@images/sleepingJobster.svg?react'

export default function NotLive() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={2}
      gap={2}
      width={634}
      m="auto"
    >
      <SleepingJobster style={{ width: 152, height: 87 }} />
      <Typography variant="subtitle3" align="center" color="textPrimary">
        <FormattedMessage id="reach_stats_campaign_not_live_title" />
      </Typography>
      <Typography variant="body2" align="center" color="textSecondary">
        <FormattedMessage id="reach_stats_campaign_not_live_content" />
      </Typography>
    </Box>
  )
}
