import React from 'react'
import { FormattedMessage } from 'react-intl'
import CloseIcon from '@mui/icons-material/Close'
import EmailOutlined from '@mui/icons-material/EmailOutlined'
import { Box, Typography, IconButton, Button } from '@mui/material'
import { CtaFlag } from '../../../types'
import RowFlex from '../../shared/RowFlex'

export type CTAProps = {
  ctaFlag: CtaFlag
  hasContactButton: boolean
}

function CTA({ ctaFlag, hasContactButton }: CTAProps) {
  // TODO: make CTA dismissible
  // https://heyjobs.atlassian.net/browse/EPG-955
  function onDismiss() {
    // eslint-disable-next-line no-console
    console.log('you tried to dismiss the CTA', ctaFlag)
  }

  // TODO: implement CTA action, opening a contact form
  //https://heyjobs.atlassian.net/browse/EPG-960
  function onAction() {
    // eslint-disable-next-line no-console
    console.log('you tried to take action on the CTA', ctaFlag)
  }

  return (
    <Box
      data-testid={`cta-${ctaFlag}`}
      sx={{
        position: 'relative',
        py: 1,
        px: 2,
        bgcolor: 'common.white',
        borderRadius: 2,
      }}
    >
      <IconButton
        size="small"
        onClick={onDismiss}
        sx={{ position: 'absolute', top: 8, right: 8 }}
        data-testid="cta-close-btn"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id={`reach_stats_summary_cta_${ctaFlag}_title`} />
      </Typography>
      <RowFlex justifyContent="space-between">
        <Typography variant="caption" color="text.secondary">
          <FormattedMessage id={`reach_stats_summary_cta_${ctaFlag}_description`} />
        </Typography>
        {hasContactButton && (
          <Button
            data-testid="cta-action-btn"
            startIcon={<EmailOutlined />}
            onClick={onAction}
            sx={{ flexShrink: 0 }}
            size="small"
          >
            <FormattedMessage id="reach_stats_summary_cta_contact_button_label" />
          </Button>
        )}
      </RowFlex>
    </Box>
  )
}

export default CTA
