import React from 'react'
import { FormattedMessage } from 'react-intl'
import CampaignIcon from '@mui/icons-material/Campaign'
import EmailOutlined from '@mui/icons-material/EmailOutlined'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { useAppDispatch, useAppSelector } from '@hooks/store'
import { useCurrentCompany } from '@hooks/useCompanies'
import * as actionCreators from '../../../actions/creators'
import { PROMOTE_WITH_HIRE_DIALOG } from '../../../constants'
import StaticLink from '../../../jobz_ui_components/StaticLink'
import useTrackEvent from '../../../tracking/snowplow/hooks/useTrackEvent'
import { isPublished } from '../../../utils/jobUtils'
import { getPreviewLink } from '../../JobForm/jobFormUtils/jobFormDataUtils'
import Tooltip from '../../Tooltip'
import { JobPerformanceTableProps, JobPerformanceStats, JobPerformanceType } from './types'

const ActionIconButton = (props: { icon: React.ElementType; onClick?: () => void }) => {
  return (
    <IconButton size="small" color="primary" onClick={props.onClick}>
      <props.icon fontSize="small" />
    </IconButton>
  )
}

function Actions(props: JobPerformanceTableProps & { job: JobPerformanceStats }) {
  const variant = props.performanceType === JobPerformanceType.WORST_PERFORMING ? 'contained' : 'outlined'
  const dispatch = useAppDispatch()
  const trackEvent = useTrackEvent()
  const locale = useAppSelector((state) => state.locale)
  const company = useCurrentCompany()
  const country = company.country_code?.toLowerCase() || 'de'
  const previewLink = getPreviewLink(locale, country, company.subdomain, props.job.id)

  const isJobPublished = isPublished(props.job)
  const isJobPromoted = !!props.job.promoted
  const showTooltip = !isJobPublished || (isJobPublished && !props.job.reach_feed)
  const tooltipKey = isJobPublished
    ? 'jobcard_promote_with_hire_disabled_tooltip_reach_feed'
    : 'jobcard_promote_with_hire_disabled_tooltip_unpublished'

  async function handlePromoteWithHire() {
    dispatch(
      actionCreators.setToggle(PROMOTE_WITH_HIRE_DIALOG, {
        show: true,
        jobId: props.job.id,
        extend: isJobPromoted,
      })
    )
    trackEvent({
      name: 'rp_job_promote_initiated',
      data: { job_uid: props.job.id },
    })
  }

  function handleEmail() {
    throw new Error('Not implemented')
  }

  return (
    <Box display="flex" gap={1} whiteSpace="nowrap">
      <Tooltip disableHoverListener={!showTooltip} title={<FormattedMessage id={tooltipKey} />}>
        <span>
          <Button
            variant={variant}
            startIcon={<CampaignIcon />}
            size="small"
            onClick={handlePromoteWithHire}
            sx={{ flexShrink: 0 }}
            disabled={showTooltip}
          >
            <FormattedMessage id="jobcard_promote_with_hire_btn" />
          </Button>
        </span>
      </Tooltip>
      <ActionIconButton icon={EmailOutlined} onClick={handleEmail} />
      <StaticLink href={previewLink} newTab>
        <ActionIconButton icon={VisibilityOutlined} />
      </StaticLink>
    </Box>
  )
}

export default Actions
