import React from 'react'
import { Box, Typography } from '@mui/material'
import { useFormatMessage } from '@hooks/util'
import { useCampaignStats } from '../../../pages/Statistics/Reach/hooks/useReachStats'
import { CtaFlag } from '../../../types'
import CTA from './CTA'
import SummaryCard from './SummaryCard'

const orderedCta: CtaFlag[] = [
  'prevent_jobs_below_target',
  'increase_avg_jaqs_score',
  'promote_reach_with_hire',
  'encourage_shift_from_linkout',
  'sync_back_candidate_status',
  'request_acm_consultation',
]

function CTASection() {
  const formatMessage = useFormatMessage()
  const { stats } = useCampaignStats()
  const ctaFlags: CtaFlag[] = stats!.cta_flags
  const hasContactButton = (ctaFlag: CtaFlag): boolean =>
    ['increase_avg_jaqs_score', 'request_acm_consultation'].includes(ctaFlag)

  return (
    <SummaryCard title={formatMessage('reach_stats_summary_ctas_title')}>
      <Typography variant="body2">{formatMessage('reach_stats_summary_ctas_description')}</Typography>
      <Box display="flex" flexDirection="column" gap={1.5} my={1.5}>
        {orderedCta
          .filter((flag) => ctaFlags.includes(flag)) // don't use `ctaFlags.map`, to make sure we follow the order of `orderedCta`
          .slice(0, 3) // pick only the first 3 CTAs
          .map((flag) => (
            <CTA key={flag} ctaFlag={flag} hasContactButton={hasContactButton(flag)} />
          ))}
      </Box>
    </SummaryCard>
  )
}

export default CTASection
