import React from 'react'
import { FormattedMessage } from 'react-intl'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { useAppDispatch } from '@hooks/store'
import { useCurrentCompany } from '@hooks/useCompanies'
import { useInvalidateQuery } from '@hooks/util/useInvalidateQuery'
import { useJobSearch } from '@hooks/util/useJobSearch'
import * as actionCreators from '../../../actions/creators'
import { enqueueSnackbar } from '../../../actions/notifications'
import { NOTIFICATIONS } from '../../../constants'
import { ReachStatsQueryKeys } from '../../../pages/Statistics/Reach/hooks/useReachStats'
import useTrackEvent from '../../../tracking/snowplow/hooks/useTrackEvent'
import { WithRequired } from '../../../types'
import Authorized from '../../authorization/Authorized'
import RuntimeAndBudget from '../../ProductSelection/components/RuntimeAndBudget'
import { RUNTIME_OPTIONS } from '../../ProductSelection/constants'
import { runtimeToPeriods } from '../../ProductSelection/utils'
import DialogActions from '../DialogActions'
import { PromoteWithHireDialogViewProps } from '../types'
import type { JobRuntimeType } from '../../ProductSelection/types'

type FormData = {
  runtime: JobRuntimeType
}

const validationSchema = yup.object({
  runtime: yup.number().required(),
})

function RuntimeView(props: WithRequired<PromoteWithHireDialogViewProps, 'onClose' | 'productSelection'>) {
  const dispatch = useAppDispatch()
  const company = useCurrentCompany()
  const { searchJobs: refreshDashboard } = useJobSearch()
  const trackEvent = useTrackEvent()
  const invalidateQuery = useInvalidateQuery()

  const formik = useFormik({
    validationSchema,
    initialValues: {
      runtime: RUNTIME_OPTIONS[0],
    },
    async onSubmit(values: FormData) {
      if (props.extend) {
        await dispatch(
          actionCreators.extendPromotionJob(props.job.company_id, props.job.id, {
            preferred_budget_per_thirty_days_euros: props.productSelection.preferred_budget_per_thirty_days,
            runtime: values.runtime,
            selected_product: props.productSelection.selected_product,
            available_products: props.productSelection.available_products,
          })
        )

        await refreshDashboard()
        invalidateQuery(ReachStatsQueryKeys.jobPerformanceStats)

        dispatch(
          enqueueSnackbar({
            message: { id: NOTIFICATIONS.employer_notification_job_promotion_extended },
            options: { variant: 'success' },
          })
        )

        trackEvent({
          name: 'rp_job_promote_extended',
          data: { job_uid: props.job.id },
        })
      } else {
        await dispatch(
          actionCreators.promoteJob(props.job.company_id, props.job.id, {
            preferred_budget_per_thirty_days_euros: props.productSelection.preferred_budget_per_thirty_days,
            runtime: values.runtime,
            selected_product: props.productSelection.selected_product,
            available_products: props.productSelection.available_products,
          })
        )

        await refreshDashboard()
        invalidateQuery(ReachStatsQueryKeys.jobPerformanceStats)

        dispatch(
          enqueueSnackbar({
            message: { id: NOTIFICATIONS.employer_notification_job_promoted },
            options: { variant: 'success' },
          })
        )

        trackEvent({
          name: 'rp_job_promoted',
          data: { job_uid: props.job.id },
        })
      }

      props.onClose()
    },
  })

  const totalCost = props.productSelection.preferred_budget_per_thirty_days * runtimeToPeriods(formik.values.runtime)
  const hasEnoughBudget = company.balance >= totalCost

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Box py={2}>
          <Typography variant="subtitle3" pb={1}>
            <FormattedMessage
              id={props.extend ? 'extend_promote_with_hire_runtime_subtitle' : 'promote_with_hire_runtime_subtitle'}
            />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              id={props.extend ? 'extend_promote_with_hire_runtime_body' : 'promote_with_hire_runtime_body'}
            />
          </Typography>
        </Box>
        <RuntimeAndBudget totalBudget={totalCost} hidePublishAt showHireBudget voucherCodeDiscountAmount={0} />
        {!hasEnoughBudget && (
          <Alert
            severity="error"
            sx={{ mt: 2 }}
            action={
              <Authorized permission="purchase_budget">
                <Button data-testid="top-up-btn" color="inherit" href="/purchase">
                  <FormattedMessage id="budget_screen_top_up_button" />
                </Button>
              </Authorized>
            }
          >
            <Typography variant="body2" whiteSpace="pre-line">
              <FormattedMessage id="promote_with_hire_runtime_view_not_enough_budget" />
            </Typography>
          </Alert>
        )}
        <DialogActions handleBack={props.onBack} submit nextDisabled={!hasEnoughBudget} />
      </FormikProvider>
    </form>
  )
}

export default RuntimeView
