import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useAppSelector } from '@hooks/store'
import { tabs } from '../JobAdQualityContent'
import { JobAdQualityFormValues } from '../types'

const useTabAutoSwitch = (tab, setTab) => {
  const { dirty } = useFormikContext<JobAdQualityFormValues>()
  const [autoSwitchTab, setAutoSwitchTab] = React.useState(true)
  const { loading, potentialJobTransparencyScoreGain, potentialTextQualityScoreGain } = useAppSelector(
    ({ jobAdQuality }) => ({
      loading: jobAdQuality.textQuality.loading || jobAdQuality.jobTransparency.loading,
      potentialJobTransparencyScoreGain: jobAdQuality.jobTransparency.potentialJobTransparencyScoreGain,
      potentialTextQualityScoreGain: jobAdQuality.textQuality.potentialTextQualityScoreGain,
    })
  )

  useEffect(() => {
    // if quality score calculation is done and the form data is not updated
    if (!dirty && !loading) {
      // if the user is on the job transparency tab and there is no potential score gain
      if (
        autoSwitchTab &&
        tab === tabs.job_transparency &&
        potentialJobTransparencyScoreGain === 0 &&
        potentialTextQualityScoreGain !== 0
      ) {
        setTab(tabs.text_quality)
      }
      setAutoSwitchTab(false)
    }
  }, [autoSwitchTab, dirty, loading, potentialJobTransparencyScoreGain, potentialTextQualityScoreGain, setTab, tab])
}

export default useTabAutoSwitch
