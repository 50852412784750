import React, { PropsWithChildren, ReactNode } from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'

type Props = {
  icon: ReactNode
}

function TypographyWithIcon(props: PropsWithChildren<TypographyProps & Props>) {
  return (
    <Typography {...props} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {props.children}
      {props.icon}
    </Typography>
  )
}

export default TypographyWithIcon
