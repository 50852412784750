import { TableCellProps } from '@mui/material/TableCell'
import { MRT_ColumnDef } from 'material-react-table'
import { JobStatistics } from '../../../types'
import { getFormattedPartialUID } from '../../../utils/formatters'
import { $colors } from '../../dependencies/variables'

const secondLevelHeaderProps: {
  muiTableHeadCellProps: TableCellProps
} = {
  muiTableHeadCellProps: {
    sx: {
      '.parent-header': {
        div: {
          justifyContent: 'left !important',
        },
      },
    },
  },
}

const defineProps = (size: number, align: 'left' | 'right' = 'left') => ({
  size,
  muiTableBodyCellProps: {
    sx: {
      border: `1px solid ${$colors.gray[250]}`,
      padding: '16px',
    },
    align,
  },
  muiTableHeadCellProps: {
    align,
  },
})

const formattedHeader = (id: string, formatMessage: (key: string) => string) =>
  formatMessage(`reach_stats_jobs_overview_${id}_header`)

export const columnsFactory = (formatMessage: (key: string) => string): MRT_ColumnDef<JobStatistics>[] => [
  {
    header: formattedHeader('job_id', formatMessage),
    accessorKey: 'id',
    accessorFn: (row) => getFormattedPartialUID(row.id),
    enableSorting: false,
    enableHiding: false,
    ...defineProps(128),
    muiTableHeadCellProps: {
      sx: {
        borderTop: 'none !important', // Hides the border with the block over it
      },
    },
  },
  {
    header: formattedHeader('job', formatMessage),
    accessorKey: 'job',
    columns: [
      {
        header: formattedHeader('title', formatMessage),
        accessorKey: 'title',
        ...defineProps(250),
      },
      {
        header: formattedHeader('city', formatMessage),
        accessorKey: 'city',
        ...defineProps(128),
      },
    ],
  },
  {
    header: formattedHeader('performance_during_period', formatMessage),
    columnDefType: 'group',
    accessorKey: 'performance_during_period',
    columns: [
      {
        header: formattedHeader('days_live', formatMessage),
        accessorKey: 'days_live',
        ...defineProps(136, 'right'),
      },
      {
        header: formattedHeader('views', formatMessage),
        accessorKey: 'views',
        ...defineProps(107, 'right'),
      },
      {
        header: formattedHeader('applications_started', formatMessage),
        columnDefType: 'group',
        accessorKey: 'applications_started_group',
        enableSorting: false,
        ...secondLevelHeaderProps,
        columns: [
          {
            header: formattedHeader('total', formatMessage),
            accessorKey: 'applications_started',
            ...defineProps(96, 'right'),
          },
          {
            header: formattedHeader('views_to_applications_started_ratio', formatMessage),
            accessorKey: 'views_to_applications_started_ratio',
            ...defineProps(167, 'right'),
          },
        ],
      },
      {
        header: formattedHeader('applications_submitted', formatMessage),
        accessorKey: 'applications_submitted_group',
        columnDefType: 'group',
        enableSorting: false,
        ...secondLevelHeaderProps,
        columns: [
          {
            header: formattedHeader('total', formatMessage),
            accessorKey: 'applications_submitted',
            ...defineProps(96, 'right'),
          },
          {
            header: formattedHeader('applications_submitted_to_start_ratio', formatMessage),
            accessorKey: 'applications_submitted_to_start_ratio',
            ...defineProps(167, 'right'),
          },
        ],
      },
      {
        header: formattedHeader('applications_sent', formatMessage),
        accessorKey: 'applications_sent_group',
        columnDefType: 'group',
        enableSorting: false,
        ...secondLevelHeaderProps,
        columns: [
          {
            header: formattedHeader('total', formatMessage),
            accessorKey: 'applications_sent',
            ...defineProps(96, 'right'),
          },
          {
            header: formattedHeader('applications_submitted_to_sent_ratio', formatMessage),
            accessorKey: 'applications_submitted_to_sent_ratio',
            ...defineProps(155, 'right'),
          },
        ],
      },
      {
        header: formattedHeader('gold_candidates', formatMessage),
        accessorKey: 'gold_candidates_group',
        columnDefType: 'group',
        enableSorting: false,
        ...secondLevelHeaderProps,
        columns: [
          {
            header: formattedHeader('total', formatMessage),
            accessorKey: 'gold_applications_sent',
            ...defineProps(96, 'right'),
          },
          {
            header: formattedHeader('applications_sent_gold_ratio', formatMessage),
            accessorKey: 'applications_sent_gold_ratio',
            ...defineProps(155, 'right'),
          },
        ],
      },
    ],
  },
  {
    header: formattedHeader('current_data', formatMessage),
    accessorKey: 'current_data',
    columns: [
      {
        header: formattedHeader('current_status', formatMessage),
        accessorKey: 'current_status',
        ...defineProps(160),
        enableSorting: false,
      },
      {
        header: formattedHeader('current_quality_score', formatMessage),
        accessorKey: 'current_quality_score',
        ...defineProps(175, 'right'),
      },
      {
        header: formattedHeader('screening_questions_count', formatMessage),
        accessorKey: 'screening_questions_count',
        ...defineProps(235, 'right'),
      },
      {
        header: formattedHeader('current_marketing_contract_group_name', formatMessage),
        accessorKey: 'current_marketing_contract_group_name',
        ...defineProps(240),
        enableSorting: false,
      },
      {
        header: formattedHeader('current_candidate_delivery', formatMessage),
        accessorKey: 'current_candidate_delivery',
        accessorFn: (row) => (row.current_is_linkout ? 'Linkout' : 'Direct'),
        ...defineProps(240),
        enableSorting: false,
      },
    ],
  },
]
