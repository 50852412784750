import React, { useLayoutEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip as ChartTooltip,
} from 'chart.js'
import Annotation from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import BestLeastPerformingJobs from '../../../components/Statistics/BestLeastPerformingJobs'
import CampaignFunnel from '../../../components/Statistics/CampaignFunnel'
import BreakableYAxis from '../../../components/Statistics/CampaignFunnel/BreakableYAxis'
import JobsPerformanceStatistics from '../../../components/Statistics/JobsPerformanceStatistics'
import LiveJobsVsCpaChart from '../../../components/Statistics/LiveJobsVsCpaChart'
import Loader from '../../../components/Statistics/Loader'
import Summary from '../../../components/Statistics/Summary'
import { ReachStatsFilters } from '../../../types'
import Filters from './components/Filters'
import NotLive from './components/NotLive'
import WarningPanel from './components/WarningPanel'
import { defaultState, ReachStatsContext } from './contexts/ReachStatsContext'
import { useCampaignStats } from './hooks/useReachStats'
import { ReachStatsContextType } from './types'

function _ReachStats() {
  const { stats, isSuccess, isLoading } = useCampaignStats()

  if (isLoading) {
    return (
      <Box height="30vh">
        <Loader />
      </Box>
    )
  }

  if (isSuccess) {
    if (!stats.was_ever_live) {
      return <NotLive />
    }

    return (
      <>
        <WarningPanel />
        <Box display="flex" flexDirection="column" gap={6}>
          <Summary />
          <CampaignFunnel />
          <BestLeastPerformingJobs />
          <LiveJobsVsCpaChart />
          <JobsPerformanceStatistics />
        </Box>
      </>
    )
  }
}

export default function ReachStats() {
  const [filters, setFilters] = useState<ReachStatsFilters>(defaultState)
  const context: ReachStatsContextType = { filters, setFilters }

  useLayoutEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      BarController,
      LineElement,
      PointElement,
      ChartTooltip,
      Legend,
      ChartDataLabels,
      Annotation,
      Title,
      Filler,
      // beforeInit injection requires the plugin to be registered globally
      BreakableYAxis
    )
  }, [])

  return (
    <ReachStatsContext.Provider value={context}>
      <Box my={3}>
        <Typography variant="subtitle1">
          <FormattedMessage id="statistics_subtitle_reach" />
        </Typography>
      </Box>
      <Filters />
      <_ReachStats />
    </ReachStatsContext.Provider>
  )
}
