import React from 'react'
import { SvgIcon, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import Badge from '@mui/material/Badge'
import { useField } from 'formik'
import { useAppDispatch, useCurrentJob } from '@hooks/store'
import RecommendationActive from '@images/recommendation-active-badge.svg?react'
import RecommendationInactive from '@images/recommendation-inactive-badge.svg?react'
import { updateBudgetScreenState } from '../../../actions/creators'
import { $colors, $sizes } from '../../dependencies/variables'
import { RUNTIME_OPTIONS } from '../constants'

const RECOMMENDED_RUNTIME = '90'

const RuntimeSelector = () => {
  const job = useCurrentJob()
  const dispatch = useAppDispatch()
  const [{ name, value }, , { setValue, setTouched }] = useField('runtime')

  return (
    <ToggleButtonGroup
      sx={{
        'height': $sizes.xxxl,
        '.Mui-selected': {
          borderColor: $colors.primary[500],
        },
      }}
      value={value}
      onChange={(_, newRuntime) => {
        if (newRuntime !== null) {
          setValue(newRuntime)
          setTouched(true)
          dispatch(updateBudgetScreenState(job?.id, name, newRuntime))
        }
      }}
      exclusive
      aria-label="runtime"
      color="primary"
      data-testid="runtime"
    >
      {RUNTIME_OPTIONS.map((runtime, numberOfMonths) => {
        const isRecommendedRuntime = value === RECOMMENDED_RUNTIME
        const isSelected = value === runtime

        return (
          <Badge
            key={runtime}
            badgeContent={
              <SvgIcon
                width="20px"
                height="20px"
                component={isRecommendedRuntime ? RecommendationActive : RecommendationInactive}
              />
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '.MuiBadge-anchorOriginTopRight': {
                right: '18px',
              },
            }}
            invisible={runtime !== RECOMMENDED_RUNTIME}
          >
            <ToggleButton
              sx={{ width: $sizes.xxxl, borderColor: 'primary' }}
              value={runtime}
              aria-label={runtime}
              data-testid={`runtime-${runtime}`}
            >
              <Typography variant="subtitle3" color={isSelected ? 'textPrimary' : 'textSecondary'}>
                {numberOfMonths + 1}
              </Typography>
            </ToggleButton>
          </Badge>
        )
      })}
    </ToggleButtonGroup>
  )
}

export default RuntimeSelector
