import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { ExpandLess } from '@mui/icons-material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useFormatMessage } from '@hooks/util/useFormatMessage'
import { useJobPerformanceStats } from '../../../pages/Statistics/Reach/hooks/useReachStats'
import { paths } from '../../../routes/paths'
import SwitchButton from '../../../ui_components/SwitchButton'
import { getFormattedPartialUID } from '../../../utils/formatters'
import { InfoIconWithTooltip } from '../../InfoIconWithTooltip'
import PromoteWithHireDialog from '../../PromoteWithHireDialog'
import Loader from '../Loader'
import Actions from './Actions'
import { DEFAULT_CRITERIA, sortOptions } from './data'
import JobPerformanceTable from './JobPerformanceTable'
import { JobPerformanceType, SortOption, TableFields } from './types'

const cells: TableFields[] = [
  {
    key: 'id',
    overrides: {
      children: (_, job) => <Link to={paths.jobApplications({ jobId: job.id })}>{getFormattedPartialUID(job.id)}</Link>,
    },
  },
  {
    key: 'title',
    overrides: { sx: { width: '100%' } },
  },
  { key: 'city' },
  {
    key: 'criteria',
    overrides: { align: 'right' },
  },
  {
    key: 'actions',
    overrides: {
      children: (props, job) => <Actions {...props} job={job} />,
      sx: { width: 'max-content' },
    },
  },
]

function Title() {
  const formatMessage = useFormatMessage()
  return (
    <Typography variant="h6" display="flex" alignItems="center">
      <FormattedMessage id="reach_stats_best_least_performing_jobs_title" />
      <InfoIconWithTooltip
        tooltip={formatMessage('reach_stats_best_least_performing_jobs_tooltip')}
        iconSize="small"
        style={{ marginLeft: 8 }}
      />
    </Typography>
  )
}

function BestLeastPerformingJobs() {
  const [performanceType, setPerformanceType] = useState<JobPerformanceType>(JobPerformanceType.BEST_PERFORMING)
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
  const [criteria, setCriteria] = useState<SortOption>(DEFAULT_CRITERIA)
  const { data, isLoading } = useJobPerformanceStats(criteria)
  const sortByOpen = Boolean(sortAnchorEl)

  const jobData = data?.[performanceType] ?? []

  function handleSortClick(event: React.MouseEvent<HTMLButtonElement>) {
    setSortAnchorEl(event.currentTarget)
  }
  function handleSortClose(value?: SortOption) {
    setSortAnchorEl(null)
    if (value) {
      setCriteria(value)
    }
  }
  function handlePerformanceTypeChange(newPerformanceType: JobPerformanceType) {
    setPerformanceType(newPerformanceType)
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Title />
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" mr={1} color="text.secondary">
            <FormattedMessage id="reach_stats_sort_by_label" />
          </Typography>
          <Button
            sx={{ textTransform: 'none' }}
            endIcon={
              sortByOpen ? (
                <ExpandLess sx={{ color: 'text.secondary' }} />
              ) : (
                <ExpandMore sx={{ color: 'text.secondary' }} />
              )
            }
            onClick={handleSortClick}
          >
            <FormattedMessage id={`reach_stats_sort_by_${criteria}`} />
          </Button>
          <Menu anchorEl={sortAnchorEl} open={sortByOpen} onClose={() => handleSortClose()}>
            {sortOptions.map((option) => (
              <MenuItem key={option} onClick={() => handleSortClose(option)}>
                <FormattedMessage id={`reach_stats_sort_by_${option}`} />
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <SwitchButton
          labelLeft={<FormattedMessage id="reach_stats_least_performing" />}
          labelRight={<FormattedMessage id="reach_stats_best_performing" />}
          leftSelected={performanceType === JobPerformanceType.WORST_PERFORMING}
          onClickLeft={() => handlePerformanceTypeChange(JobPerformanceType.WORST_PERFORMING)}
          onClickRight={() => handlePerformanceTypeChange(JobPerformanceType.BEST_PERFORMING)}
        />
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
          <Loader />
        </Box>
      ) : (
        <JobPerformanceTable jobs={jobData!} performanceType={performanceType} sortBy={criteria} cells={cells} />
      )}
      <PromoteWithHireDialog />
    </Box>
  )
}

export default BestLeastPerformingJobs
