import React, { useMemo } from 'react'
import { Table, TableHead, TableRow } from '@mui/material'
import { MRT_TableInstance } from 'material-react-table'
import { JobStatistics } from '../../../types'
import { Level1Header, Level2Header, Level3Header } from './HelperComponents'

export default function Toolbar({ table }: { table: MRT_TableInstance<JobStatistics> }) {
  const headerGroups = useMemo(() => {
    const _headerGroups = table.getHeaderGroups()
    return {
      level1: _headerGroups[0],
      level2: _headerGroups[1],
      level3: _headerGroups[2],
    }
  }, [table])

  const { columnVisibility, sorting } = table.getState()

  const level1Headers = useMemo(
    () =>
      headerGroups.level1.headers.map(
        (header, index) =>
          header.column.getIsVisible() && (
            <Level1Header
              key={index}
              align="left"
              colSpan={header.colSpan}
              style={{ borderBottom: header.isPlaceholder ? 'none' : 'initial' }} // this is for the empty block on the Job ID column
              header={header}
              table={table}
            >
              {header.column.columnDef.header}
            </Level1Header>
          )
      ),
    [columnVisibility]
  )

  const level2And3Headers = useMemo(() => {
    const elements: React.JSX.Element[] = []
    const headers = headerGroups.level3.headers
    let superTitleIndex: number = 5
    for (let titleIndex = 0; titleIndex < headers.length; titleIndex++) {
      const header = headers[titleIndex]
      if (header.column.depth === 2) {
        if (header.column.getIsVisible()) {
          const level2Header = headerGroups.level2.headers[superTitleIndex]
          const level3TotalHeader = headers[titleIndex]
          const level3RatioHeader = headers[titleIndex + 1]
          elements.push(
            <Level2Header
              key={titleIndex}
              header={level2Header}
              table={table}
              width={header.getSize()}
              sx={{ padding: '0 !important' }}
            >
              <TableRow>
                <Level2Header
                  className="parent-header"
                  table={table}
                  header={level2Header}
                  width={header.getSize()}
                  rowSpan={1}
                  borderless
                />
              </TableRow>
              <TableRow>
                <Level3Header
                  table={table}
                  header={level3TotalHeader}
                  rowSpan={1}
                  width={level3TotalHeader.getSize()}
                  sx={{ borderLeft: 'none' }}
                />
                <Level3Header
                  table={table}
                  header={level3RatioHeader}
                  rowSpan={1}
                  sx={{ borderRight: 'none' }}
                  width={level3RatioHeader.getSize()}
                />
              </TableRow>
            </Level2Header>
          )
          titleIndex++
          superTitleIndex++
        }
      } else {
        if (header.column.getIsVisible()) {
          elements.push(
            <Level2Header key={titleIndex} table={table} header={header} rowSpan={2} width={header.getSize()} />
          )
        }
      }
    }
    return elements
  }, [columnVisibility, sorting])

  return (
    <Table>
      <TableHead>
        <TableRow>{level1Headers}</TableRow>
        <TableRow>{level2And3Headers}</TableRow>
      </TableHead>
    </Table>
  )
}
